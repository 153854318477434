import { transformPaymentIcons } from '@activebrands/core-web/libs/storyblok/utils/transform-payment-icons';
import { transformPopups } from '@activebrands/core-web/libs/storyblok/utils/transform-popups';
import { graphql, useStaticQuery } from 'gatsby';

const useSiteQuery = () => {
    const { application } = useStaticQuery(
        graphql`
            {
                application {
                    data {
                        config {
                            siteConfig {
                                popups {
                                    _uid
                                    closeButtonColor
                                    content
                                    cookieLifespan
                                    timeOut
                                    visibilitySettings
                                }
                                paymentIcons {
                                    ...Icon
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const content = application.data.config.siteConfig || {};
    return {
        popups: content.popups?.length > 0 ? transformPopups(content.popups) : [],
        paymentIcons: content.paymentIcons?.length > 0 ? transformPaymentIcons(content.paymentIcons) : [],
    };
};

export default useSiteQuery;
